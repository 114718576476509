import React, { useState, useEffect } from "react";
import { Core } from "@walletconnect/core";
import { Web3Wallet } from "@walletconnect/web3wallet";
import {
  buildApprovedNamespaces,
  formatJsonRpcResult,
} from "@walletconnect/utils";
import { ethers } from "ethers";
import close from "./assets/close.png";
import banner from "./assets/wcTutorial.8fbcd219d90c753cbe61.png";
import accountABI from "./accountABI.json";

const WC_PROJECT_ID = "224ba800aea0df26b8045219ddfef582";

const NFTModal = ({ provider, nft, signer, onClose }) => {
  const [WC_URI, setWC_URI] = useState(""); // State for user input
  const [web3wallet, setWeb3wallet] = useState(null);

  useEffect(() => {
    const initializeWallet = async () => {
      const metadata = {
        name: "erc-6551-walletconnect",
        description: "connect erc-6551 wallet to ui using walletconnect",
        url: "www.walletconnect.com",
        icons: [],
      };

      const core = new Core({
        projectId: WC_PROJECT_ID,
        logger: "debug",
      });

      const wallet = await Web3Wallet.init({ core, metadata });
      setWeb3wallet(wallet);

      // Set up event listeners once the wallet is initialized
      wallet.on("session_proposal", async (proposal) => {
        const approvedNamespaces = buildApprovedNamespaces({
          proposal: proposal.params,
          supportedNamespaces: {
            eip155: {
              chains: ["eip155:1"],
              methods: ["eth_sendTransaction", "personal_sign"],
              events: ["accountsChanged", "chainChanged"],
              accounts: [`eip155:1:${nft.nftAccount}`],
            },
          },
        });

        await wallet.approveSession({
          id: proposal.id,
          namespaces: approvedNamespaces,
        });
      });

      wallet.on("session_request", async (event) => {
        // console.log("event", event);
        const { topic, params, id } = event;
        // console.log("topic", topic);
        //console.log("params", params);
        const { request } = params;
        const requestParamsMessage = request.params[0];
        //console.log("requestParamsMessage", requestParamsMessage);

        if (request.method == "personal_sign") {
          try {
            // console.log("Message to sign:", requestParamsMessage);

            const signedMessage = await signer.signMessage(
              ethers.getBytes(requestParamsMessage)
            );
            // console.log("signed", signedMessage);

            const response = { id, result: signedMessage, jsonrpc: "2.0" };
            await wallet.respondSessionRequest({ topic, response });
          } catch (error) {
            // console.log(error);
            await wallet.respondSessionRequest({
              topic,
              response: {
                id,
                error: { code: -32603, message: error.message },
              },
            });
          }
        } else if (request.method == "eth_sendTransaction") {
          //console.log("event", event);
          const response = {
            id,
            jsonrpc: "2.0",
            error: {
              code: 5000,
              message:
                "Alert: Redirecting Action From User To Token Bound Account.",
            },
          };

          await wallet.respondSessionRequest({
            topic,
            response,
          });
          // console.log("ELSE");
          // console.log("Data", requestParamsMessage.data);

          const accountInstance = new ethers.Contract(
            nft.nftAccount,
            accountABI,
            signer
          );

          const gas = await provider.getFeeData();

          try {
            const tx = await accountInstance.execute(
              requestParamsMessage.to,
              requestParamsMessage.value
                ? ethers.toBigInt(requestParamsMessage.value)
                : 0,
              requestParamsMessage.data,
              0,
              {
                gasPrice: gas.gasPrice,
                gasLimit: 500000, // Adjust based on expected complexity
              }
            );

            await tx.wait();
          } catch (error) {
            // Respond with an error
            await wallet.respondSessionRequest({
              topic,
              response: {
                jsonrpc: "2.0",
                id,
                error: {
                  code: 12001,
                  message:
                    "Alert:RedirectingActionFromUserToTokenBoundAccount.",
                },
              },
            });
          }
        }
      });
    };

    initializeWallet();
  }, [WC_URI]); // Empty dependency array means this runs only once

  const handleConnect = async () => {
    if (!web3wallet) return;

    const connecting = await web3wallet.core.pairing.pair({
      uri: WC_URI,
      activatePairing: true,
    });

    //console.log(`User input: ${WC_URI}`); // Log user input
    //console.log(`Connecting ${nft.nftAccount} to DApp...`);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="rounded-lg shadow-lg p-6 max-w-[600px] w-full">
        <button
          className="absolute top-2 right-2 text-gray-500"
          onClick={onClose}
        >
          <img src={close} alt="" className="w-[40px]" />
        </button>

        <div className="flex flex-col items-center text-[15px] text-center">
          <h3>1 - Copy Link from Wallet Connect modal</h3>
          <h3>2 - Paste in Input Field Above and Click "Connect"</h3>
          <h3>3 - Continue Navigation with your connected Incept NFT!</h3>
        </div>

        <img src={banner} alt="" className="mt-2" />

        <h2 className="text-lg font-semibold text-center mb-2">{nft.name}</h2>
        <input
          type="text"
          placeholder="Enter WC URI"
          className="border text-black outline-none border-gray-300 p-2 w-full mb-4"
          value={WC_URI} // Bind input value
          onChange={(e) => setWC_URI(e.target.value)} // Update state on change
        />
        <button
          onClick={handleConnect}
          className="bg-white text-black font-bold px-4 py-2 w-full"
        >
          Connect NFT to DApp
        </button>
      </div>
    </div>
  );
};

export default NFTModal;
