import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import NFTModal from "./NFTModal";
import contractABI from "./inceptAbi.json";
import "./index.css"; // or './App.css'

function App() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState();
  const [account, setAccount] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [selectedNft, setSelectedNft] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [contract, setContract] = useState();

  useEffect(() => {
    const loadNFTs = async () => {
      if (!account || !provider) return; // Ensure provider is set

      const contractAddress = "0x1c43cD666F22878ee902769FCCdA61F401814EFb";

      const nftContract = new ethers.Contract(
        contractAddress,
        contractABI,
        signer
      );

      console.log("signer", contractABI, contractAddress, signer);

      setContract(nftContract);

      try {
        // Fetch the NFTs owned by the account
        const owned = await nftContract.owned(account);
        // const owner = await nftContract.owner();

        // Fetch NFT details concurrently
        const nftPromises = owned?.map(async (item) => {
          const nftAddress = await nftContract.account(item);
          return {
            id: Number(item),
            url: `https://erc4d-server-production.up.railway.app/gif/${item}`,
            nftAccount: nftAddress,
          };
        });

        // Wait for all promises to resolve and update state with all NFT data
        const nftData = await Promise.all(nftPromises);
        //  console.log("nftData", nftData);
        setNfts(nftData); // Set nfts as a flat array
      } catch (error) {
        console.log(error);
      }
    };

    loadNFTs();
  }, [account, provider]);

  const connectWallet = async () => {
    if (window.ethereum) {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const accounts = await provider.send("eth_requestAccounts", []);

      setProvider(provider);
      setAccount(accounts[0]);
      setSigner(signer);
    } else {
      alert("MetaMask is not installed");
    }
  };

  const openModal = (nft) => {
    setSelectedNft(nft);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedNft(null);
  };

  return (
    <div className="w-full flex flex-col items-center p-4 bg-black text-white min-h-screen">
      <h1 className=" sm:text-3xl text-xl font-bold mb-6 text-center">
        My Incept Collection
      </h1>

      <button onClick={connectWallet} className="bg-white px-4 py-2 text-black">
        {account
          ? account.slice(0, 6) + "..." + account.slice(-6)
          : "Connect Wallet"}
      </button>

      <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {nfts?.map((nft) => (
          <div
            key={nft.id}
            onClick={() => openModal(nft)}
            className="cursor-pointer mt-10 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105"
          >
            <img
              src={nft.url}
              alt={`NFT ${nft.id}`}
              className="max-w-72 w-full object-cover mb-2" // Set image size to 300x300 pixels
            />
            <h3 className="text-xs font-semibold p-2 text-center text-white">
              NFT Account:
            </h3>
            <p className="p-2 text-center text-xs text-gray-500 truncate">
              {nft.nftAccount}
            </p>
          </div>
        ))}
      </div>
      {modalOpen && (
        <NFTModal
          provider={provider}
          contract={contract}
          nft={selectedNft}
          signer={signer}
          onClose={closeModal}
        />
      )}
    </div>
  );
}

export default App;
